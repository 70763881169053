@import url('https://fonts.googleapis.com/css2?family=Anybody:ital,wdth,wght@0,50..150,100..900;1,50..150,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;

:root {
  --green: #33B4A0;
  --green-hover: #33b4a133;
  --black: #232B2B;
  --black-2: #00000033;
  --black-bg: #333333;
  --padding-x-lg: 120px;
  --padding-y-lg: 72px;
  --padding-x-sm: 60px;
  --nav-link-w: 250px;
  --base-height: 22px;
}


body {
  font-family: "Barlow", sans-serif;
  color: var(--black);
  line-height: var(--base-height);
}






/*# Typography */
.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Anybody", sans-serif;
}

.title {
  @apply text-[32px] leading-[38px] sm:text-[57px] sm:leading-[62px] sm:font-semibold;
}

h1 {
  @apply text-2xl leading-7 font-bold sm:text-[46px] sm:leading-[56px] sm:font-semibold;
}

h2 {
  @apply text-xl leading-6 sm:text-[37px] sm:leading-[48px] sm:font-semibold;
}

h3 {
  @apply text-[19px] leading-[26px] sm:text-[29px] sm:leading-9 font-semibold;
}

h4 {
  @apply sm:text-[23px] sm:leading-[30px] font-semibold;
}

h5 {
  @apply text-xl leading-[26px] font-semibold;
}


.p-sm {
  @apply text-[13px] leading-5;
}

.cap-lg {
  @apply text-[11px] leading-4;
}

.cap-sm {
  @apply text-[9px] leading-4;
}






/*# Styling */
.center {
  @apply xl:w-[1440px] xl:mx-auto;
}

.button-black {
  @apply font-semibold bg-[--black] hover:bg-[--green] px-[34px] py-2.5 text-white transition-colors;
}

.button-outline {
  @apply font-semibold ring-1 ring-inset ring-[--black] px-[34px] py-2.5 hover:bg-[--green] hover:ring-0 text-[--black] hover:text-white transition-colors
}

.input-border {
  @apply ring-1 ring-[#33333333] hover:ring-[--black-bg] focus-visible:ring-black
}

.mission-bg {
  background-image: url('./assets/mission-bg-shape.svg');
  background-position: -40px 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.mission-bg::before {
  @apply content-[''] absolute top-0 left-0 size-full bg-[#2EB3A0] -z-10;
}

.broucher-bg {
  position: relative;
  background-color: var(--black-bg);
  z-index: 1;
}

.broucher-bg::before,
.broucher-bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.broucher-bg::before {
  background-image: url('./assets/broucher-shape-1.svg');
  background-position: 13% 8%;
}

.nav-search-shadow {
  box-shadow: 0 4px 34px #00000026;
}

.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-bottom {
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fade-in {
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-top {
  animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}







/*# Media Queries */
/* Laptop and desktop screens */
@media screen and (min-width: 767px) {
  .mission-bg {
    background-position: 120px;
  }

  .broucher-bg::after {
    background-image: url('./assets/broucher-shape-2.svg');
    background-position: bottom right;
  }
}

/* Laptop and mobile screens */
@media screen and (max-width: 1439px) {

  /* Navbar appear */
  .slide-in-right {
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* Products and resources links and sublinks */
  .scale-up-ver-top {
    animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
}





/*# Dropdown */
@media screen and (min-width:1440px) {

  .dropdown-menu,
  .dropdown-sub {
    display: none;
  }

  .dropdown:hover .dropdown-menu,
  .drop-sub-hover:hover .dropdown-sub {
    display: block;
  }

  .dropdown-shadow {
    box-shadow: 0 4px 10px #0000001a;
  }

  .dropdown-sub-shadow {
    box-shadow: 4px 4px 10px #0000001a;
  }
}






/*# Navigation slider */
/* Customer slider in homepage navigation style */
.prev-customers,
.next-customers {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.prev-customers {
  left: 0;
}

.next-customers {
  right: 0;
}

/* Slider in popup navigation styles */
.prev-popup,
.next-popup {
  position: absolute;
  top: 1px;
  cursor: pointer;
}

.prev-popup {
  left: 0;
}

.next-popup {
  right: 0;
}



/* # Pagination slider */
/* Home */
.home-testimonial .swiper-pagination {
  @apply sm:absolute sm:!left-[300px] sm:!top-[180px];
}

.home-testimonial .swiper-pagination-bullet-active {
  background-color: var(--black-bg) !important;
}


/* About */
.about-testimonial .swiper-pagination {
  @apply sm:absolute !-bottom-0;
}

.about-testimonial .swiper-pagination-bullet {
  background-color: #F2F2F2 !important;
}






/*# Animations */
@keyframes slide-in-top {
  0% {
    transform: translateY(-334px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(334px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    transform: translateY(150%);
    opacity: 0;
  }

  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

@tailwind utilities;